import { useSelector } from "react-redux"
import { pm } from "../utils"

function Menu() {
  const user = useSelector(state => state.user)
  const path = window.location.pathname

  return (
    <nav className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main">
      <div className="scrollbar-inner">
        {/* Brand */}
        <div className="sidenav-header d-flex align-items-center">
          <a className="navbar-brand" href="/">
            <img src="../../assets/img/brand/logo-pequeno.png" className="navbar-brand-img" />
          </a>
          <div className="ml-auto">
            {/* Sidenav toggler */}
            <div
              className="sidenav-toggler d-xl-block active"
              data-action="sidenav-unpin"
              data-target="#sidenav-main"
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          {/* Collapse */}
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">
            {/* Nav items */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className={`nav-link`} href="/">
                  <i className="fas fa-home text-primary" />
                  <span className="nav-link-text">Dashboards</span>
                </a>
              </li>
              {pm(user, 1) && (
                <li className="nav-item">
                  <a className={`nav-link`} href="/maquinas-fixas">
                    <i className="fas fa-network-wired text-warning" />
                    <span className="nav-link-text">Máquinas</span>
                  </a>
                </li>
              )}
              {![74].includes(user.id) && pm(user, 2) && (
                <li className="nav-item">
                  <a className={`nav-link`} href="/credenciais">
                    <i className="fas fa-unlock text-info" />
                    <span className="nav-link-text">Credenciais</span>
                  </a>
                </li>
              )}
              {![74].includes(user.id) && pm(user, 2) && (
                <li className="nav-item">
                  <a className={`nav-link`} href="/credenciais/portal-consignado">
                    <i className="fas fa-unlock text-info" />
                    <span className="nav-link-text">Credenciais - Consignado</span>
                  </a>
                </li>
              )}
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  href="#navbar-importacoes"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbar-importacoes"
                >
                  <i class="fa fa-solid fa-upload text-success"></i>
                  <span className="nav-link-text">Importações</span>
                </a>
                <div className="collapse" id="navbar-importacoes">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <a href="/importacoes/cpfs" className="nav-link">
                        CPFs Refin Atraso
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  href="#navbar-exportacoes"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbar-exportacoes"
                >
                  <i class="fa fa-solid fa-download text-indigo"></i>
                  <span className="nav-link-text">Exportações</span>
                </a>
                <div className="collapse" id="navbar-exportacoes">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <a href="/exportacoes/comissoes" className="nav-link">
                        Comissões
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/exportacoes/propostas-recusadas" className="nav-link">
                        Propostas Recusadas
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <div>
              {pm(user, [3, 4, 5, 6, 7, 8, 9]) && (
                <div>
                  <hr className="my-3" />
                  <h6 className="navbar-heading p-0 text-muted">APLICAÇÕES</h6>
                </div>
              )}
              <ul className="navbar-nav mb-md-3">
                {pm(user, [8, 9, 7, 5, 13, 15, 16, 99]) && (
                  <li className="nav-item">
                    <a
                      className="nav-link collapsed"
                      href="#navbar-poupaconsig"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="navbar-poupaconsig"
                    >
                      <img className="img-icon-sm" src="/assets/img/icons/poupaconsig.png" />
                      <span className="nav-link-text">Poupanegócios</span>
                    </a>
                    <div className="collapse" id="navbar-poupaconsig">
                      <ul className="nav nav-sm flex-column">
                        {pm(user, 8) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/conexoes" className="nav-link">
                              Conexões
                            </a>
                          </li>
                        )}
                        {pm(user, 16) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/configuracoes" className="nav-link">
                              Configurações
                            </a>
                          </li>
                        )}
                        {pm(user, 9) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/desconexoes" className="nav-link">
                              Desconexões por Erro
                            </a>
                          </li>
                        )}
                        {pm(user, 7) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/erros" className="nav-link">
                              Erros
                            </a>
                          </li>
                        )}
                        {pm(user, 5) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/live" className="nav-link">
                              Live
                            </a>
                          </li>
                        )}
                        {pm(user, 5) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/metricas-csg" className="nav-link">
                              Métricas CSG
                            </a>
                          </li>
                        )}
                        {pm(user, 15) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/propostas" className="nav-link">
                              Propostas
                            </a>
                          </li>
                        )}
                        {pm(user, 15) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/propostas-rascunhos" className="nav-link">
                              Propostas (Rascunhos)
                            </a>
                          </li>
                        )}
                        {pm(user, 99) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/propostas-banco" className="nav-link">
                              Propostas (Banco)
                            </a>
                          </li>
                        )}
                        {pm(user, [5, 15, 99]) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/propostas-parceiros" className="nav-link">
                              Propostas (Parceiros)
                            </a>
                          </li>
                        )}
                        {pm(user, 8) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/conexoes" className="nav-link">
                              Sessões
                            </a>
                          </li>
                        )}
                        {![74].includes(user.id) && pm(user, 13) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/siglas" className="nav-link">
                              Siglas
                            </a>
                          </li>
                        )}
                        {![74].includes(user.id) && pm(user, 13) && (
                          <li className="nav-item">
                            <a href="/poupanegocios/siglas-compartilhadas" className="nav-link">
                              Siglas Compartilhadas
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {pm(user, [17, 6, 8, 11, 18]) && (
                  <li className="nav-item">
                    <a
                      className="nav-link collapsed"
                      href="#navbar-santander"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="navbar-santander"
                    >
                      <img className="img-icon-sm" src="/assets/img/icons/banks/santander.png" />
                      <span className="nav-link-text">Santander</span>
                    </a>
                    <div className="collapse" id="navbar-santander">
                      <ul className="nav nav-sm flex-column">
                        {pm(user, 17) && (
                          <li className="nav-item">
                            <a href="/santander/robo/contratos" className="nav-link">
                              Robô - Contratos
                            </a>
                          </li>
                        )}
                        {pm(user, 6) && (
                          <li className="nav-item">
                            <a href="/santander/robo/taxa-convenio" className="nav-link">
                              Robô - Taxa por Convênio
                            </a>
                          </li>
                        )}
                        {pm(user, [6, 8, 11, 17, 18]) && (
                          <li className="nav-item">
                            <a href="/santander/robo/cancelamento-proposta" className="nav-link">
                              Robô - Cancelamento Proposta
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {pm(user, [11]) && (
                  <li className="nav-item">
                    <a
                      className="nav-link collapsed"
                      href="#navbar-sim"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="navbar-sim"
                    >
                      <img className="img-icon-sm" src="/assets/img/icons/banks/sim.png" />
                      <span className="nav-link-text">Sim</span>
                    </a>
                    <div className="collapse" id="navbar-sim">
                      <ul className="nav nav-sm flex-column">
                        {pm(user, 11) && (
                          <li className="nav-item">
                            <a href="/sim/robo/cp" className="nav-link">
                              Robô - Crédito Pessoal
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {pm(user, [17, 6]) && (
                  <li className="nav-item">
                    <a
                      className="nav-link collapsed"
                      href="#navbar-facta"
                      data-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="navbar-facta"
                    >
                      <img className="img-icon-sm" src="/assets/img/icons/banks/facta.png" />
                      <span className="nav-link-text">Facta</span>
                    </a>
                    <div className="collapse" id="navbar-facta">
                      <ul className="nav nav-sm flex-column">
                        {pm(user, 17) && (
                          <li className="nav-item">
                            <a href="/facta/regras" className="nav-link">
                              Fgts Regras
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div>
              {pm(user, [0]) && (
                <div>
                  <hr className="my-3" />
                  <h6 className="navbar-heading p-0 text-muted">RELATÓRIOS</h6>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="scroll-element scroll-x scroll-scrolly_visible" style={{}}>
          <div className="scroll-element_outer">
            <div className="scroll-element_size" />
            <div className="scroll-element_track" />
            <div className="scroll-bar" style={{ width: 0 }} />
          </div>
        </div>
        <div className="scroll-element scroll-y scroll-scrolly_visible" style={{}}>
          <div className="scroll-element_outer">
            <div className="scroll-element_size" />
            <div className="scroll-element_track" />
            <div className="scroll-bar" style={{ height: 770, top: 0 }} />
          </div>
        </div> */}
      </div>
    </nav>
  )
}

export default Menu
