import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import TableV2 from "../components/TableV2"
import { LOGOUT, SET_TITLE } from "../redux/Actions"
import { pm } from "../utils"

const https = require("https")
const { REACT_APP_SERVIDOR } = process.env

function CredenciaisPortalConsignado(props) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const table = useRef()

  const [permitted, setPermitted] = useState(false)
  const [filter, setFilter] = useState(null)
  const [convenios, setConvenios] = useState([])
  const [bancos, setBancos] = useState([])
  const [selected, setSelected] = useState(null)

  const [mac, setMac] = useState(null)
  const [cpf, setCpf] = useState(null)
  const [senha, setSenha] = useState(null)
  const [convenio, setConvenio] = useState(null)
  const [banco, setBanco] = useState(null)
  const [nomeComputador, setNomeComputador] = useState(null)

  useEffect(() => {
    checkPermission()
    listarConveniosPortalConsignado()
    listarBancosPortalConsignado()
  }, [])

  const columns = [
    {
      id: "ativo",
      name: "Status",
      sortable: true,
      cell: row => (
        <span className="badge badge-dot">
          <i className={`bg-${row.ativo ? "success" : "danger"}`}></i>
          <span style={{ color: "#525f7f" }} className="status">
            {row.ativo ? "Ativo" : "Inativo"}
          </span>
        </span>
      ),
    },
    {
      id: "nome_computador",
      name: "VM",
      selector: "nome_computador"
    },
    {
      id: "mac",
      name: "MAC",
      selector: "mac"
    },
    {
      id: "cpf",
      name: "CPF",
      selector: "cpf"
    },
    {
      id: "senha",
      name: "Senha",
      selector: "senha"
    },
    {
      id: "nome_convenio",
      name: "Convênio",
      selector: "nome_convenio",
      width: 350,
    },
    {
      id: "nome_banco",
      name: "Banco",
      selector: "nome_banco"
    },
    {
      id: "total_consulta",
      name: "Total Consultas",
      selector: "total_consulta"
    },
    {
      id: "criado_em",
      name: "Criado Em",
      selector: "criado_em"
    },
    {
      id: "atualizado_em",
      name: "Atualizado Em",
      selector: "atualizado_em"
    }
  ]

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1")
  }

  const macMask = (value) => {
    return value
      .replace(/\W/g, "")
      .replace(/(.{2})(?=.)/g, "$1:")
      .slice(0, 17);
  };

  const checkPermission = () => {
    if (!pm(user, 2)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else if ([74].includes(user.id)) {
      props.history.push("/")
      toast.error("Você não tem permissão para acessar esta página.")
    } else {
      setPermitted(true)
      dispatch({ type: SET_TITLE, payload: "Credenciais - Portal Consignado" })
    }
  }

  const onReset = async () => {
    setSelected(null);
    setMac(null)
    setCpf(null)
    setSenha(null)
    setConvenio("")
    setNomeComputador(null)
    setBanco("")
  }

  const limparCamposFormulario = () => {
    // Limpar os inputs
    const inputs = document.querySelectorAll('form input.form-control');
    inputs.forEach(item => {
      item.value = "";
      item.removeAttribute("disabled");
    });

    onReset();
  }

  const editarCredencial = async (row) => {
    setSelected(row)
    setCpf(row.cpf)
    setSenha(row.senha)
    setMac(row.mac)
    setConvenio(row.id_convenio)
    setNomeComputador(row.nome_computador)
    setBanco(row.id_banco)
  }

  async function listarConveniosPortalConsignado() {
    try {
      const resultado = await axios.get(
        `${REACT_APP_SERVIDOR}geral/convenios/portal-consignado`,
        {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          })
        }
      )

      const { status } = resultado;
      const { dados, retorno } = resultado.data;

      if (status === 200 && retorno) {
        setConvenios(dados);
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao listar os convênios")
    }
  }

  async function listarBancosPortalConsignado() {
    try {
      const resultado = await axios.get(
        `${REACT_APP_SERVIDOR}geral/portal-consignado/bancos/listar`,
        {
          headers: { token: user.token },
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          })
        }
      )

      const { status } = resultado;
      const { dados, retorno } = resultado.data;

      if (status === 200 && retorno) {
        setBancos(dados);
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao listar os bancos")
    }
  }

  async function saveForm() {
    try {
      let valid = true

      if (cpf == null) {
        valid = false
        toast.warning("O CPF é um campo obrigatório")
      }

      if (senha == null) {
        valid = false
        toast.warning("A senha é um campo obrigatório")
      }

      if (convenio == null) {
        valid = false
        toast.warning("O convênio é um campo obrigatório")
      }

      if (mac == null || nomeComputador == null) {
        valid = false
        toast.warning("O MAC e o nome do comptuador são campos obrigatórios")
      }

      if (banco == null) {
        valid = false
        toast.warning("O Banco é um campo obrigatório")
      }

      if (valid) {
        if (selected) {
          const credencialId = selected.id
          const cpf = selected.cpf

          toast.promise(
            () => {
              return new Promise(async (resolve, reject) => {
                try {
                  await axios.patch(
                    `${REACT_APP_SERVIDOR}portal-consignado/credencial/${credencialId}/atualizar`,
                    {
                      mac,
                      nome_computador: nomeComputador,
                      cpf,
                      senha,
                      convenio_id: convenio,
                      banco_id: banco
                    },
                    {
                      headers: { token: user.token },
                      httpsAgent: new https.Agent({
                        rejectUnauthorized: false,
                      }),
                    }
                  )

                  if (table) {
                    table.current.refresh()
                  }

                  listarConveniosPortalConsignado()
                  onReset()
                  resolve()

                  window.$("#modal-form").modal("hide");
                } catch (error) {
                  reject(error)
                }
              })
            },
            {
              pending: `Atualizando CPF "${cpf}"...`,
              success: `CPF "${cpf}" atualizada com sucesso!`,
              error: `Falha ao atualizar o CPF "${cpf}". Verifique sua conexão e tente novamente.`,
            }
          )
        } else {
          toast.promise(
            () => {
              return new Promise(async (resolve, reject) => {
                try {
                  await axios.post(
                    `${REACT_APP_SERVIDOR}portal-consignado/credencial/cadastrar`,
                    {
                      mac,
                      nome_computador: nomeComputador,
                      cpf,
                      senha,
                      convenio_id: convenio,
                      banco_id: banco
                    },
                    {
                      headers: { token: user.token },
                      httpsAgent: new https.Agent({
                        rejectUnauthorized: false,
                      }),
                    }
                  )

                  if (table) {
                    table.current.refresh()
                  }

                  listarConveniosPortalConsignado()
                  onReset()
                  resolve()

                  window.$("#modal-form").modal("hide");
                } catch (error) {
                  reject(error)
                }
              })
            },
            {
              pending: `Cadastrando novo CPF "${cpf}"...`,
              success: `CPF "${cpf}" cadastrada com sucesso!`,
              error: `Falha ao cadastrar o CPF "${cpf}". Verifique sua conexão e tente novamente.`,
            }
          )
        }
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao salvar os dados do formulário")
    }
  }

  async function removerCredencial(dados) {
    const credencialId = dados.id;
    const cpf = dados.cpf;

    toast.promise(
      () => {
        return new Promise(async (resolve, reject) => {
          try {
            await axios.patch(
              `${REACT_APP_SERVIDOR}portal-consignado/credencial/${credencialId}/remover`,
              {},
              {
                headers: { token: user.token },
                httpsAgent: new https.Agent({
                  rejectUnauthorized: false,
                }),
              }
            )

            if (table) {
              table.current.refresh()
            }

            listarConveniosPortalConsignado()
            onReset()
            resolve()
          } catch (error) {
            reject(error)
          }
        })
      },
      {
        pending: `Deletando o CPF "${cpf}"...`,
        success: `CPF "${cpf}" deletada com sucesso!`,
        error: `Falha ao deletar o CPF "${cpf}". Verifique sua conexão e tente novamente.`,
      }
    )
  }

  return (
    <div className="container-fluid mt--6">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header border-0">
              <div className="row">
                <div className="col-5">
                  <h3 className="mb-0">Credenciais</h3>
                </div>
                <div className="col-7" style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
                  <div className="col-4 text-right">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#modal-form"
                      className="btn btn-sm btn-primary btn-round btn-icon"
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Adicionar</span>
                    </button>
                  </div>
                  <div className="col-3 text-right">
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Pesquisar..."
                      aria-controls="datatable-basic"
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableV2
              ref={table}
              filter={filter}
              table={"credenciais/portal-consignado"}
              columns={[
                ...columns,
                {
                  id: "actions",
                  name: "",
                  cell: row => (
                    <div>
                      <a
                        href="#!"
                        className="table-action"
                        data-toggle="modal"
                        data-target="#modal-form"
                        onClick={() => editarCredencial(row)}
                      >
                        <i className="fas fa-edit" />
                      </a>
                      <a
                        href="#!"
                        className="table-action"
                        onClick={() => removerCredencial(row)}
                      >
                        <i className="fas fa-trash" />
                      </a>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal-form"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-form"
        style={{ display: "none" }}
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card bg-secondary border-0 mb-0">
              <div className="card-body px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <strong>Adicionar</strong>
                </div>
                <form role="form">
                  {/* MAC */}
                  <div className="form-group">
                    <label class="form-control-label">
                      MAC:*
                    </label>
                    <div className="input-group input-group-merge input-group-alternative">
                      <input
                        maxLength="14"
                        value={mac}
                        onChange={(e) => setMac(macMask(e.target.value))}
                        className="form-control"
                        type="text"
                        required
                      />
                    </div>
                  </div>

                  {/* Nome Computador */}
                  <div className="form-group">
                    <label class="form-control-label">
                      Nome Computador:*
                    </label>
                    <div className="input-group input-group-merge input-group-alternative">
                      <input
                        maxLength="14"
                        value={nomeComputador}
                        onChange={(e) => setNomeComputador(e.target.value.trim())}
                        className="form-control"
                        type="text"
                        required
                      />
                    </div>
                  </div>

                  {/* CPF */}
                  <div className="form-group">
                    <label class="form-control-label">
                      CPF:*
                    </label>
                    <div className="input-group input-group-merge input-group-alternative">
                      <input
                        maxLength="14"
                        value={cpf}
                        onChange={(e) => setCpf(cpfMask(e.target.value))}
                        className="form-control"
                        type="text"
                        required
                      />
                    </div>
                  </div>

                  {/* Senha */}
                  <div className="form-group">
                    <label class="form-control-label">Senha:*</label>
                    <div className="input-group input-group-merge input-group-alternative">
                      <input
                        value={senha}
                        onChange={(e) => setSenha(e.target.value.trim())}
                        className="form-control"
                        type="text"
                        required
                      />
                    </div>
                  </div>

                  {/* Convênio */}
                  <div className="form-group">
                    <label className="form-control-label">Convênio</label>
                    <select
                      name="convenio"
                      id="convenio"
                      className="form-control"
                      value={convenio}
                      onChange={(e) => setConvenio(e.target.value)}
                    >
                      <option value="">Selecione um convênio</option>
                      {convenios.map(item => (
                        <option value={item.id}>{item.nome}</option>
                      ))}
                    </select>
                  </div>

                  {/* Bancos */}
                  <div className="form-group">
                    <label className="form-control-label">Bancos</label>
                    <select
                      name="banco"
                      id="banco"
                      className="form-control"
                      value={banco}
                      onChange={(e) => setBanco(e.target.value)}
                    >
                      <option value="">Selecione um banco</option>
                      {bancos.map(item => (
                        <option value={item.id}>{item.nome}</option>
                      ))}
                    </select>
                  </div>

                  <div className="row">
                    <div className="col-12 text-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={saveForm}
                      >
                        Salvar
                      </button>
                      <button
                        type="button"
                        className="btn btn-default text-white"
                        data-dismiss="modal"
                        onClick={limparCamposFormulario}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default CredenciaisPortalConsignado